///
/// Future Imperfect by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Wrapper */

	#wrapper {
		@include vendor('display', 'flex');
		@include vendor('flex-direction', 'row-reverse');
		@include vendor('transition', 'opacity #{_duration(menu)} ease');
		margin: 0 auto;
		max-width: 100%;
		opacity: 1;
		padding: (_size(section-spacing) * 1.5);
		width: 90em;

		body.is-menu-visible & {
			opacity: 0.15;
		}

		@include breakpoint(xlarge) {
			padding: _size(section-spacing);
		}

		@include breakpoint(large) {
			display: block;
		}

		@include breakpoint(small) {
			padding: 0px 1.5em;
		}

		body.single & {
			display: block;
		}
	}